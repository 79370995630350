<template>
  <div>
    <el-main>
      <el-form>
        <el-row type="flex" justify="end">
          <el-col :span="2">
            <el-form-item>
              <el-button
                type="primary"
                @click="openDialog('add')"
                v-permission="['admin:taskTemplate:add']"
                >新增</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <!-- 表格 -->

      <el-table
        border
        style="width: 100%"
        :data="tableData"
        v-loading="tableLoading"
      >
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="描述" prop="description"></el-table-column>
        <el-table-column label="创建人" prop="creatorName"></el-table-column>
        <el-table-column label="创建日期" prop="createDate">
          <template slot-scope="scope">
            <span>{{ scope.row.createDate | formatDateFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="最后更新人" prop="updaterName"></el-table-column>
        <el-table-column label="最后更新日期" prop="updateDate">
          <template slot-scope="scope">
            <span>{{ scope.row.updateDate | formatDateFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="启用状态" prop="enabled">
          <template slot-scope="scope">
            <span>{{ scope.row.enabled == 1 ? "已启用" : "已禁用" }} </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" >
          <template slot-scope="scope">
            <el-button
              v-permission="['admin:taskTemplate:edit']"
              type="primary"
              size="mini"
              @click="openDialog('edit', scope.row)"
              >编辑</el-button
            >
            <el-button
              v-permission="['admin:taskTemplate:enabled']"
              type="primary"
              size="mini"
              @click="editEnabledTaskTemplate(scope.row)"
              >{{ scope.row.enabled == 0 ? "启用" : "禁用" }}</el-button
            >
            <!-- <el-button
              type="primary"
              size="mini"
              @click="deleteDict(scope.row.id)"
              >删除</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <div class="page-info-class">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.pageNum"
          :page-sizes="[5, 10, 20, 30, 50]"
          :page-size="this.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="this.total"
        ></el-pagination>
      </div>
    </el-main>

    <!-- 编辑 -->
    <edit
      v-if="editDialog"
      :editDialog="editDialog"
      :type="typeDialog"
      v-on:listenEditClose="listenEditClose"
      :info="info"
    ></edit>
  </div>
</template>

<script>
import {
  getTaskTemplateList,
  editEnabledTaskTemplate,
} from "@/api/taskTemplate";
import edit from "../components/taskTemplate/edit";
import { formatDate } from "@/common/date";

export default {
  name: "taskTemplate",
  data() {
    return {
      tableData: [],
      editDialog: false, //打开编辑
      buttonControlDialog: false,
      info: {},
      typeDialog: "",
      tableLoading: false,
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageCount: 1,
    };
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  components: {
    edit,
  },

  mounted() {
    this.getTaskTemplateList(1, this.pageSize);
  },

  methods: {
    handleSizeChange(val) {
      this.getTaskTemplateList(1, val);
    },
    handleCurrentChange(val) {
      if (val > this.pageCount) {
        val = this.pageCount;
      }
      this.getTaskTemplateList(val, this.pageSize);
    },
    editEnabledTaskTemplate(row) {
      let msg = row.enabled == 0 ? "启用" : "禁用";
      this.$confirm("确定要" + msg + "此条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: false,
      })
        .then(() => {
          let info = {
            id: row.id,
            enabled: row.enabled == 0 ? 1 : 0,
          };
          editEnabledTaskTemplate(info).then((response) => {
            if (response.success) {
              this.getTaskTemplateList();
              this.$message({
                type: "success",
                message: "修改成功!",
              });
            } else {
              this.$message({
                type: "info",
                message: response.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },

    getTaskTemplateList(num, pageSize) {
      this.tableData = []
      let query = {};
      query.pageNum = num;
      query.pageSize = pageSize;
      query.businessType = 2;
      this.tableLoading = true;
      getTaskTemplateList(query).then((response) => {
        this.tableData = response.data;
        if (response.total != null) {
          this.pageNum = response.pageNum;
          this.pageSize = response.pageSize;
          this.total = response.total;
          this.pageCount = response.pageCount;
        } else {
          this.total = 0;
        }
        this.tableLoading = false;
      });
    },

    //新增编辑弹窗显示
    openDialog(type, row) {
      // console.log(id);
      if (type == "edit") {
        this.typeDialog = "edit";
        this.info = row;
      } else if (type == "add") {
        this.typeDialog = "add";
      }
      this.editDialog = true;
    },
    //监听编辑弹窗关闭
    listenEditClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.editDialog = !isClose;
      if (type == "sure") {
        this.getTaskTemplateList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
