import request from '@/utils/request'

//任务模版 start
export function getTaskTemplateList(param) {
    return request({
        url: '/admin/task/template/paging-list',
        method: 'post',
        data: param
    })
}

export function addTaskTemplate(param) {
    return request({
        url: '/admin/task/template/add',
        method: 'post',
        data: param
    })
}

export function editTaskTemplate(param) {
    return request({
        url: '/admin/task/template/edit',
        method: 'post',
        data: param
    })
}

//编辑启用/禁用字段 enabled”: 是否启用，1 启用，0 禁用
export function editEnabledTaskTemplate(param) {
    return request({
        url: '/admin/task/template/editEnabled',
        method: 'post',
        data: param
    })
} 

export function selectListTaskTemplate(param) {
    return request({
        url: '/admin/task/template/select-list',
        method: 'post',
        data: param
    })
}
//任务模版 end




