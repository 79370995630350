<template>
  <el-dialog
    class="edit"
    :title="title"
    :visible="editDialog"
    center
    @close="dialogClose"
    width="900px"
    :close-on-click-modal="false"
  >
    <el-form
      :model="formData"
      ref="editTaskTemplate"
      :rules="rules"
      class="addDailog"
      onsubmit="return false;"
      label-width="140px"
      label-position="right"
    >
      <el-form-item label="任务模版名称" prop="name">
        <el-input
          type="text"
          show-word-limit
          v-model="formData.name"
        ></el-input>
      </el-form-item>
      <el-form-item label="任务模版描述" prop="description">
        <el-input
          type="text"
          show-word-limit
          v-model="formData.description"
        ></el-input>
      </el-form-item>

      <!-- <el-form-item label="启用" prop="rating">
        <el-switch
          v-model="formData.enabled"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-value="1"
          inactive-value="0"
        >
        </el-switch>
      </el-form-item>     -->
    </el-form>

    <div class="dialog-footer">
      <el-button @click="dialogClose">取消</el-button>
      <el-button type="primary" @click="sure">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addTaskTemplate, editTaskTemplate } from "@/api/taskTemplate";

export default {
  name: "edit",
  data() {
    return {
      title: "新增",
      token: null,
      formData: {
        // enabled: "1",
        name: null,
        description: null,
        businessType: "2",
      },
      rules: {
        name: [
          { required: true, message: "请输入任务模版名称", trigger: "blur" },
        ],
        description: [
          { required: false, message: "请输入任务模版描述", trigger: "blur" },
        ],
      },
    };
  },
  props: {
    type: String,
    editDialog: Boolean,
    info: Object,
  },
  watch: {
    info: {
      handler(newVal, old) {
        if(newVal && this.type == "edit"){
          this.formData = JSON.parse(JSON.stringify(this.info))
        }
      },
      immediate: true,
      deep: true,
    },
  },
  components: {},
  created() {
    // this.getQualityList();
    if (this.type == "edit") {
      this.title = "编辑";
    } else if (this.type == "add") {
      this.title = "新增";
    }
  },
  methods: {
    //弹窗关闭
    dialogClose() {
      this.formData = {};
      this.$emit("listenEditClose", { type: "cancel", isClose: true });
    },

    addTaskTemplate() {
      addTaskTemplate(this.formData).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "添加成功!" });
          this.formData = {};
          this.$emit("listenEditClose", { type: "sure", isClose: true });
        }
      });
    },
    editTaskTemplate() {
      editTaskTemplate(this.formData).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "编辑成功!" });
          this.formData = {};
          this.$emit("listenEditClose", { type: "sure", isClose: true });
        }
      });
    },
    //确定
    sure() {
      this.$refs["editTaskTemplate"].validate((valid) => {
        if (valid) { 
          if (this.type == "add") {
            this.addTaskTemplate();
          } else if (this.type == "edit") {
            this.editTaskTemplate();
          }
        } else {
          console.log("-------------error");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bigTitle {
  font-size: 20px;
  margin-bottom: 15px;
}
.red {
  color: red;
}
.addDailog {
  // padding: 0 0 20px 0;
  display: flex;
  flex-wrap: wrap;
  .roleCodeList {
    width: 100% !important;
    height: auto;
    text-align: center !important;
  }
  .el-form-item {
    text-align: left;

    // display: flex;
    width: 48%;
    // float: left;
    min-height: 1rem;
    .el-select {
      width: 100% !important;
    }
    .el-input-number {
      width: 100% !important;
      text-align: left !important;
      .el-input__inner {
        text-align: left !important;
      }
    }
  }
}
</style>